import React from 'react';
import cntl from 'cntl';
import { gql } from 'graphql-request';
import { graphql, PageProps } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  CustomPageQuery,
  CustomPageContext,
  CustomPageRuntimeQuery,
} from '../@types';
import GrayRects from '../components/custom-page/gray-rects';
import CTA from '../components/custom-page/cta';
import TestimonialsSection from '../components/custom-page/testimonials';
import Footer from '../components/landing/sections/footer';
import SEO from '../components/seo';
import Header from '../components/header/header';
import useContentful from '../runtime/hooks/useContentful';
import PricingSection from '../components/custom-page/pricing';

const containerClassName = cntl`
  relative
  py-16
  overflow-hidden
`;

const illustrationOverlayClassName = cntl`
  hidden
  lg:block
  lg:absolute
  lg:inset-y-0
  lg:h-full
  lg:w-full
`;

const illustrationWrapperClassName = cntl`
  relative
  h-full
  text-lg
  max-w-prose
  mx-auto
`;

const pageContainerClassName = cntl`
  relative
  px-4
  sm:px-6
  lg:px-8
`;

const pageHeadingWrapperClassName = cntl`
  max-w-prose
  mx-auto
`;

const pageHeadingClassName = cntl`
  text-3xl
  text-center
  leading-8
  font-extrabold
  tracking-tight
  text-gray-900
  sm:text-4xl`;

const pageContentClassName = cntl`
  mt-6
  prose
  prose-primary
  prose-lg
  text-gray-500
  mx-auto
`;

const CustomPageTemplate: React.FC<
  PageProps<CustomPageQuery, CustomPageContext>
> = ({ data }) => {
  const {
    title,
    content,
    metaDescription,
    hasCta,
    ctaTitle,
    ctaSubtitle,
    ctaDescription,
    hasTestimonials,
    hasPricing,
  } = data.page;

  const runtimeData = useContentful<CustomPageRuntimeQuery>(
    runtimeQuery,
    !!hasPricing
  );

  return (
    <div className="bg-gray-100">
      <SEO
        title={title}
        description={metaDescription}
        useTitleTemplate={false}
      />
      <div className="pt-6">
        <Header
          hasMainCTA
          isCentered
          navigation={[]}
          phoneNumber={data.businessPhone.description.description}
        />
      </div>
      <div className={containerClassName}>
        <div className={illustrationOverlayClassName}>
          <div className={illustrationWrapperClassName} aria-hidden="true">
            <GrayRects />
          </div>
        </div>
        <div className={pageContainerClassName}>
          <div className={pageHeadingWrapperClassName}>
            <h1 className={pageHeadingClassName}>{title}</h1>
          </div>
          <div className={pageContentClassName}>
            {renderRichText(content, {})}
          </div>
        </div>
      </div>
      {hasTestimonials && <TestimonialsSection data={data} />}
      {hasPricing && <PricingSection data={data} runtimeData={runtimeData} />}
      <div>
        <CTA
          hasCta={hasCta}
          ctaTitle={ctaTitle}
          ctaSubTitle={ctaSubtitle}
          ctaDescription={ctaDescription}
        />
      </div>
      <Footer serviceAreas={data.serviceAreas} />
    </div>
  );
};

export default CustomPageTemplate;

const runtimeQuery = gql`
  query CustomPageRuntimeQuery {
    carTypes: carTypeCollection(limit: 20, order: order_ASC) {
      items {
        sys {
          id
        }
        subTypes
        name
        packages: packagesCollection(limit: 20) {
          items {
            sys {
              id
            }
            name
            price
            time
            calloutText
            serviceType
            services: servicesCollection(limit: 20) {
              items {
                sys {
                  id
                }
                name
                description
              }
            }
            categories: serviceCategoriesCollection(limit: 3) {
              items {
                sys {
                  id
                }
              }
            }
          }
        }
        servicePricing: servicePricingCollection(limit: 20) {
          items {
            sys {
              id
            }
            price
            time
            calloutText
            service {
              sys {
                id
              }
              name
              description
              serviceCategory {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const query = graphql`
  query CustomPageQuery($slug: String!) {
    page: contentfulCustomPage(slug: { eq: $slug }) {
      title
      metaDescription
      hasTestimonials
      hasPricing
      hasCta
      ctaTitle
      ctaSubtitle
      ctaDescription
      content {
        raw
      }
    }
    businessPhone: contentfulSiteField(alias: { eq: "business-phone" }) {
      description {
        description
      }
    }
    serviceAreas: allContentfulServiceArea {
      nodes {
        contentful_id
        city
        county
        state
      }
    }
    testimonials: allContentfulTestimonial(filter: { enabled: { eq: true } }) {
      nodes {
        contentful_id
        author
        carModel
        description {
          description
        }
      }
    }
  }
`;
