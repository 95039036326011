import React from 'react';
import cntl from 'cntl';
import { PageSectionProps } from '../../@types';
import GrayRects from '../landing/testimonial/gray-rects';
import Title from '../landing/testimonial/title';
import Line from '../landing/testimonial/line';
import CardSlider from '../landing/testimonial/card-slider';
import SocialLinks from '../landing/testimonial/social-links';
import { CustomPageQuery } from '../../@types';

const sectionClassName = cntl`
  text-center
  py-12
  bg-gray-100
  overflow-hidden
  md:py-20
  lg:py-24
  px-4
`;

const testimonialContainerClassName = cntl`
  relative
  max-w-7xl
  mx-auto
  px-4
  sm:px-6
  lg:px-8
`;

const TestimonialsSection: React.FC<PageSectionProps<CustomPageQuery>> = ({
  data,
}) => {
  return (
    <section id="testimonials" className={sectionClassName}>
      <Title
        primary="Here's what our customers"
        secondary="are saying about us"
      />
      <Line />
      <div className={testimonialContainerClassName}>
        <GrayRects />
        <div className="relative">
          <CardSlider testimonials={data.testimonials} />
          <SocialLinks />
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
