import React, { Fragment, useState } from 'react';
import cntl from 'cntl';
import { CubeTransparentIcon } from '@heroicons/react/solid';
import {
  CarTypeSelectorValue,
  CustomPageQuery,
  CustomPageRuntimeQuery,
  PageSectionProps,
  ServiceTypeEnum,
} from '../../@types';
import CarTypes from '../landing/pricing/car-types';
import PackageCard from '../landing/pricing/package-card';
import Title from '../landing/pricing/title';
import GrayRectsTop from '../landing/pricing/gray-rects-top';
import GrayRectsMid from '../landing/pricing/gray-rects-mid';
import ServiceCard from '../landing/pricing/service-card';
import ServiceType from '../landing/pricing/service-type';
import CoatingPackage from '../landing/pricing/coating-package';

const sectionClassName = cntl`
  bg-gray-100
  relative
  overflow-hidden
`;

const wrapperClassName = cntl`
  max-w-7xl
  mx-auto
  px-4
  sm:px-6
  lg:px-8
  pb-12
`;

const packageGridClassName = cntl`
  lg:grid
  lg:grid-cols-3
  lg:gap-4
  mt-10
`;

const serviceGridClassName = cntl`
  lg:grid
  lg:grid-cols-3
  lg:gap-4
  xl:grid-cols-4
  mt-10
`;

const loadingWrapperClassName = cntl`
  flex
  items-center
  justify-center
  mt-20
`;

const loadingIconClassName = cntl`
  animate-spin
  w-10
  h-10
  text-gray-300
`;

const PricingSection: React.FC<
  PageSectionProps<CustomPageQuery, CustomPageRuntimeQuery>
> = ({ runtimeData }) => {
  const [selectedCarType, setSelectedCarType] = useState<CarTypeSelectorValue>({
    carTypeId: '2iGGABqOYwWPC5UnEDDJYW',
    subType: 'Sedan',
  });

  const [serviceType, setServiceType] = useState<ServiceTypeEnum>('Detailing');

  if (typeof runtimeData === 'undefined') {
    return null;
  }

  const findCarType = () => {
    return runtimeData.data?.carTypes.items.find(
      ({ sys: { id } }) => id === selectedCarType.carTypeId
    );
  };

  const selectedCarTypeInstance = findCarType();

  return (
    <section id="pricing" className={sectionClassName}>
      {runtimeData.data !== null ? <GrayRectsTop /> : null}
      {selectedCarTypeInstance !== undefined && <GrayRectsMid />}
      <div className="relative">
        <Title
          primary="Simple and fair prices"
          secondary="Please choose your vehicle type to preview pricing"
        />
        <div className={wrapperClassName}>
          {runtimeData.data === null ? (
            <div className={loadingWrapperClassName}>
              <CubeTransparentIcon className={loadingIconClassName} />
            </div>
          ) : (
            <CarTypes
              onSelect={setSelectedCarType}
              selected={selectedCarType}
              carTypes={runtimeData.data.carTypes}
            />
          )}
          {selectedCarTypeInstance !== undefined ? (
            <>
              <ServiceType selected={serviceType} onSelect={setServiceType} />
              {serviceType === 'Detailing' ? (
                <>
                  <div className={packageGridClassName}>
                    {selectedCarTypeInstance.packages.items.map(pkg => (
                      <Fragment key={pkg.sys.id}>
                        {pkg.serviceType === 'Detailing' && (
                          <PackageCard pkg={pkg} key={pkg.sys.id} />
                        )}
                      </Fragment>
                    ))}
                  </div>
                  <div className={serviceGridClassName}>
                    {selectedCarTypeInstance.servicePricing.items.map(
                      servicePricing => (
                        <ServiceCard
                          servicePricing={servicePricing}
                          key={servicePricing.sys.id}
                        />
                      )
                    )}
                  </div>
                </>
              ) : (
                <>
                  {selectedCarTypeInstance.packages.items.map(pkg => (
                    <Fragment key={pkg.sys.id}>
                      {pkg.serviceType === 'Coating' && (
                        <CoatingPackage pkg={pkg} key={pkg.sys.id} />
                      )}
                    </Fragment>
                  ))}
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
