import React from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';

interface CTAProps {
  hasCta: boolean;
  ctaTitle: string | null;
  ctaSubTitle: string | null;
  ctaDescription: string | null;
}

const containerClassName = cntl`
  max-w-3xl
  mx-auto
  text-center
  py-16
  px-4
  sm:py-20
  sm:px-6
  lg:px-8
`;

const titleWrapperClassName = cntl`
  text-3xl
  font-extrabold
  text-white
  sm:text-4xl
`;

const descriptionClassName = cntl`
  mt-4
  text-lg
  leading-6
  text-primary-200
`;

const ctaButtonClassName = cntl`
  mt-8 w-full
  inline-flex
  items-center
  justify-center
  px-5
  py-3
  border
  border-transparent
  text-base
  font-medium
  rounded-md
  text-primary-600
  bg-white
  hover:bg-primary-50
  sm:w-auto
`;

const CTA: React.FC<CTAProps> = ({
  hasCta,
  ctaTitle,
  ctaSubTitle,
  ctaDescription,
}) => {
  if (!hasCta) {
    return null;
  }

  return (
    <div className="bg-primary-700">
      <div className={containerClassName}>
        <h2 className={titleWrapperClassName}>
          <span className="block">{ctaTitle}</span>
          <span className="block">{ctaSubTitle}</span>
        </h2>
        <p className={descriptionClassName}>{ctaDescription}</p>
        <Link id="custom-page-cta" to="/booking" className={ctaButtonClassName}>
          Book now
        </Link>
      </div>
    </div>
  );
};

export default CTA;
